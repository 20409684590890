<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{
              name: 'DashboardHome',
            }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'Branches' }">分店管理</b-breadcrumb-item>
            <b-breadcrumb-item active>{{ title }}</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row mb-2 align-items-end">
        <div class="col-12 d-flex items-center">
          <h4 class="font-weight-bold">
            {{ title }}
          </h4>
        </div>
        <div class="col-12 d-flex items-center">
          <b-breadcrumb class="mb-2 p-0" :items="breadcrumb"></b-breadcrumb>
        </div>
      </div>

      <b-overlay :show="isLoading" rounded opacity="0.6" spinner-small spinner-variant="primary">
        <section class="row mb-8">
          <div class="col-12 col-xl-8">
            <b-form-group v-if="isShow" label-cols="12" label-cols-lg="2" label-size="sm" label="上層分店" label-for="parent_id">
              <b-breadcrumb class="mb-0 p-0" :items="breadcrumb"></b-breadcrumb>
            </b-form-group>
            <b-form-group v-else label-cols="12" label-cols-lg="2" label-size="sm" label="上層分店" label-for="parent_id">
              <b-form-select id="parent_id" class="mb-2 mr-sm-2 mb-sm-0" v-model="form.parent_id" :options="branchOptions" disabled></b-form-select>
            </b-form-group>

            <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="類型" label-for="branch_type">
              <b-form-select id="parent_id" class="mb-2 mr-sm-2 mb-sm-0" v-model="state.form.branch_type"
                :state="v$.form.branch_type.$error ? false : null" :options="branchTypes" :disabled="isShow"></b-form-select>
              <b-form-invalid-feedback :state="!v$.form.branch_type.$error">
                此欄位為必填
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="店名" label-for="name">
              <b-form-input id="name" class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="state.form.name"
                :state="v$.form.name.$error ? false : null" :readonly="isShow"></b-form-input>
              <b-form-invalid-feedback :state="!v$.form.name.$error">
                此欄位為必填
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="分店編號" label-for="branch_code">
              <b-form-input id="branch_code" class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="state.form.branch_code"
                :state="v$.form.branch_code.$error ? false : null" :readonly="isShow"></b-form-input>
              <b-form-invalid-feedback :state="!v$.form.branch_code.$error">
                此欄位為必填
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="外部編號" label-for="outer_code">
              <b-form-input id="outer_code" class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="form.outer_code"
                :readonly="isShow"></b-form-input>
            </b-form-group>

            <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="順序" label-for="order">
              <b-form-input id="order" class="mb-2 mr-sm-2 mb-sm-0" type="number" v-model="form.order"
                :readonly="isShow"></b-form-input>
            </b-form-group>
            <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="狀態" label-for="status">
              <b-form-select id="status" class="mb-2 mr-sm-2 mb-sm-0" v-model="form.status" :options="branchStatuses"
                :disabled="isShow"></b-form-select>
            </b-form-group>
            <b-form-group label-cols-lg="2" label="地址" label-for="location_address" role="tw-city-selector"
              data-bootstrap-style data-has-zipcode>
              <div class="d-flex w-100 city-selector-set-has-value">
                <!-- 縣市選單 -->
                <select class="form-control form-control-sm county" v-model="form.location_city" :readonly="isShow"
                  :disabled="state.form.branch_type !== 'shop'"></select>

                <!-- 區域選單 -->
                <select class="form-control form-control-sm district ml-2" v-model="form.location_district"
                  :disabled="state.form.branch_type !== 'shop'" @change="handleChangeZipcode" :readonly="isShow"></select>

                <!-- 郵遞區號欄位 (建議加入 readonly 屬性，防止修改) -->
                <b-form-input class="zipcode ml-2" type="text" readonly placeholder="郵遞區號" style="width: 45%" />
              </div>
            </b-form-group>

            <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="">
              <b-form-input id="location_address" class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="form.location_address"
                :disabled="state.form.branch_type !== 'shop'" :readonly="isShow"></b-form-input>
            </b-form-group>

            <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="電話" label-for="telephone">
              <b-form-input id="telephone" class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="form.telephone"
                :disabled="state.form.branch_type !== 'shop'" :readonly="isShow"></b-form-input>
            </b-form-group>

            <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="傳真" label-for="fax">
              <b-form-input id="fax" class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="form.fax"
                :disabled="state.form.branch_type !== 'shop'" :readonly="isShow"></b-form-input>
            </b-form-group>
            <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="品牌名稱" label-for="brand">
              <b-form-input id="brand" class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="form.brand"
                :disabled="state.form.branch_type !== 'shop'" :readonly="isShow"></b-form-input>
            </b-form-group>
            <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="營業時間" label-for="biz_hours">
              <b-form-textarea id="biz_hours" rows="4" v-model="form.biz_hours"
                :disabled="state.form.branch_type !== 'shop'"></b-form-textarea>
            </b-form-group>
            <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="緯度" label-for="lat">
              <b-form-input id="lat" class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="form.lat"
                :disabled="state.form.branch_type !== 'shop'" :readonly="isShow"></b-form-input>
            </b-form-group>
            <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="經度" label-for="long">
              <b-form-input id="long" class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="form.long"
                :disabled="state.form.branch_type !== 'shop'" :readonly="isShow"></b-form-input>
            </b-form-group>
            <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="GOOGLEMAP" label-for="map_url">
              <b-form-input id="map_url" class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="form.map_url"
                :disabled="state.form.branch_type !== 'shop'" :readonly="isShow"></b-form-input>
            </b-form-group>
            <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="分類" label-for="category">
              <b-form-input id="category" class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="form.category"
                :disabled="state.form.branch_type !== 'shop'" :readonly="isShow"></b-form-input>
            </b-form-group>
          </div>
        </section>
      </b-overlay>

      <div class="d-flex justify-content-center">
        <b-button class="mr-4" variant="outline-danger" @click="handleCancel">{{ isShow ? '返回' : '取消' }}</b-button>
        <b-overlay :show="isSubmmiting" rounded opacity="0.6" spinner-small spinner-variant="primary"
          class="d-inline-block">
          <b-button v-if="!isShow" variant="success" @click="handleSubmit">{{
            isEdit ? "確認修改" : "確認新增"
          }}</b-button>
        </b-overlay>
      </div>
    </b-card>
  </div>
</template>

<script>
import { reactive } from "@vue/composition-api";
import { mapState, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import TwCitySelector from "tw-city-selector";
import branchApi from "../../../apis/branch";

export default {
  setup() {
    const state = reactive({
      form: {
        name: "",
        branch_type: "",
        branch_code: "",
      },
    });
    const rules = {
      form: {
        name: { required },
        branch_type: { required },
        branch_code: { required },
      },
    };

    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },

  data() {
    return {
      isLoading: false,
      isSubmmiting: false,
      activeBranch: null,
      form: {
        parent_id: null,
        branch_type: "",
        branch_code: "",
        outer_code: null,
        name: "",
        order: undefined,
        location_city: "",
        location_district: "",
        location_zipcode: "",
        location_address: "",
        telephone: "",
        fax: "",
        status: "",
        category: "",
      },
      twCitySelector: null,
      branchTypes: [
        { value: 'area', text: '分區' },
        { value: 'shop', text: '門市' },
      ],
      branchStatuses: [
        { value: 'init', text: '新建立' },
        { value: 'pending', text: '待審核' },
        { value: 'verify', text: '審核中' },
        { value: 'approved', text: '審核通過' },
        { value: 'rejected', text: '審核不通過' },
      ]
    };
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      myBranches: (state) => state.myBranches,
    }),
    ...mapGetters("route", ["routeQuery", "routeParams"]),
    branchID() {
      return this.$route.params.branchID;
    },
    isEdit() {
      return this.$route.name === "BranchesEdit";
    },
    isShow() {
      return this.$route.name === "BranchesShow";
    },
    title() {
      switch (this.$route.name) {
        case "BranchesEdit":
          return "修改分店";
        case "BranchesShow":
          return "分店詳細資料";
        default:
          return "新增分店";
      }
    },
    breadcrumb() {
      const root = {
        text: "最上層",
        to: {
          name: "Branches",
        },
      };

      const parents =
        this.activeBranch?.parents.map((parent) => {
          return {
            text: parent.parent_name,
            to: {
              name: "BranchesSubLayer",
              params: { branchID: parent.parent_id },
            },
          };
        }) ?? [];

      return (this.isEdit || this.isShow)
        ? [
          root,
          ...parents,
          {
            text: this.activeBranch?.name,
            active: true,
          },
        ]
        : [
          root,
          ...parents,
          ...(this.branchID
            ? [
              {
                text: this.activeBranch?.name,
                to: {
                  name: "BranchesSubLayer",
                  params: { branchID: this.activeBranch?.id },
                },
              },
            ]
            : []),
          {
            text: "新增分店",
            active: true,
          },
        ];
    },
    parentBranch() {
      if (!this.branchID) return null;
      return (this.isEdit || this.isShow)
        ? this.activeBranch.parents?.[1]
        : { parent_id: this.activeBranch.id };
    },
    branchOptions() {
      let options = [{ value: null, text: '最上層' }];

      if (this.activeBranch) {
        if (this.isEdit || this.isShow) {
          options = options.concat(this.activeBranch.parents.map((parent) => {
            return { value: parent.parent_id, text: parent.parent_name };
          }));
        } else {
          options = options.concat({ value: this.activeBranch.id, text: this.activeBranch.name });
        }
      }

      return options;
    },
  },
  mounted() {
    this.twCitySelector = new TwCitySelector({
      el: ".city-selector-set-has-value",
      elCounty: ".county",
      elDistrict: ".district",
      elZipcode: ".zipcode",
    });

    this.fetchBranch();
  },
  methods: {
    handleChangeZipcode() {
      setTimeout(() => {
        this.form.location_zipcode = document.querySelector(".zipcode").value;
      }, 0);
    },
    async fetchBranch() {
      if (!this.branchID) return;
      try {
        this.isLoading = true;
        const { data } = await branchApi.getBranch(this.branchID);
        this.activeBranch = data.data;

        const { branch_type, branch_code, name, location_city, location_district, id } =
          data.data;

        this.form.parent_id = id;

        if (this.isEdit || this.isShow) {
          this.form = {
            ...this.form,
            ...this.activeBranch,
            location_city: location_city ?? "",
            location_district: location_district ?? "",
          };
          this.state.form = {
            branch_type,
            branch_code,
            name,
          };
          this.twCitySelector.setValue(
            location_city || "",
            location_district || ""
          );
        }
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },

    async handleSubmit() {
      const result = await this.v$.$validate();
      if (!result) return;
      if (this.isEdit) {
        await this.handleUpdate();
      } else {
        await this.handleCreate();
      }
      this.$store.dispatch("general/fetchBranches")
    },

    async handleUpdate() {
      try {
        this.isSubmmiting = true;
        await branchApi.updateBranch(this.branchID, {
          ...this.form,
          ...this.state.form,
        });
        this.showSuccessPopup();
      } catch (error) {
        this.handleError(error);
      }
      this.isSubmmiting = false;
    },

    async handleCreate() {
      try {
        this.isSubmmiting = true;
        await branchApi.createBranch(this.organization, {
          ...this.form,
          ...this.state.form,
        });
        this.showSuccessPopup();
      } catch (error) {
        this.handleError(error);
      }
      this.isSubmmiting = false;
    },

    handleError(error) {
      const { status, data } = error.response;
      if (status && status === 422 && data) {
        const html = Object.values(data.message)
          .map((m) => m[0])
          .join("<br/>");
        this.$swal.fire({
          type: "error",
          html,
        });
      }
    },

    handleCancel() {
      if (this.routeParams && this.routeParams.branchID) {
        this.$router.push({
          name: "BranchesSubLayer",
          params: this.routeParams,
          query: this.routeQuery,
        });
      } else {
        this.$router.push({ name: "Branches", query: this.routeQuery });
      }
    },

    showSuccessPopup() {
      this.$swal
        .fire({
          icon: "success",
          text: this.isEdit ? "修改成功" : "新增成功",
        })
        .then(() => {
          if (this.routeParams && this.routeParams.branchID) {
            this.$router.push({
              name: "BranchesSubLayer",
              params: this.routeParams,
              query: this.routeQuery,
            });
          } else {
            this.$router.push({ name: "Branches", query: this.routeQuery });
          }
        });
    },
  },
};
</script>
